import React, { useState } from "react"
import { Button, ClickAwayListener, IconButton, TextField } from "@mui/material"
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material"
import styled from "@emotion/styled"

import BasicSwitch from "./Toggle"
import Chips from "./Chips"

const CustomChunkCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1.5rem;
  gap: 1rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(61, 61, 61, 0.6);
  box-sizing: border-box;
  .actionMenu {
    position: absolute;
    width: 8rem;
    z-index: 10;
    top: 70%;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    button {
      justify-content: flex-start;
      text-transform: capitalize;
      width: 100%;
    }
  }
`

export default function ChunkCard({ chunkId, chunkText, isActive, onUpdateChunk, onClickDelete }) {
  const [editingChunkText, setEditingChunkText] = useState(chunkText)
  const [isEditingText, setIsEditingText] = useState(false)
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(false)

  const handleUpdateChunk = async (chunkId, payload) => {
    setIsLoadingCard(true)
    await onUpdateChunk(chunkId, payload)
    setIsLoadingCard(false)
    setIsEditingText(false)
  }
  const toggleActionMenu = () => {
    setIsOpenActionMenu(!isOpenActionMenu)
  }

  return (
    <CustomChunkCard>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <BasicSwitch
            handleSave={(value) => handleUpdateChunk(chunkId, { is_active: value })}
            defaultChecked={isActive}
            disabled={isLoadingCard}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", position: "relative" }}>
          <Chips status={`Chunk ${chunkId}`} />
          <IconButton onClick={toggleActionMenu}>
            <MoreHorizIcon />
          </IconButton>
          {isOpenActionMenu && (
            <ClickAwayListener onClickAway={toggleActionMenu}>
              <div className="actionMenu">
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    setIsEditingText(true)
                    toggleActionMenu()
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{ color: "#F44336" }}
                  onClick={(event) => {
                    event.stopPropagation()
                    onClickDelete(chunkId)
                  }}
                >
                  Delete
                </Button>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        value={editingChunkText}
        onChange={(event) => {
          setEditingChunkText(event.target.value)
        }}
        disabled={!isEditingText || isLoadingCard}
      />
      {isEditingText && (
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsEditingText(false)
              setEditingChunkText(chunkText)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleUpdateChunk(chunkId, { text_chunk: editingChunkText })}
            disabled={isLoadingCard}
          >
            {isLoadingCard ? "Saving..." : "Save"}
          </Button>
        </div>
      )}
    </CustomChunkCard>
  )
}
